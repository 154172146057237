const ladiezContent = {
    german: {
        arrow: "<< ",
        headline: "KOOPERATIONEN",
        secondHeadline: "Ladiez e.V.",
        first: "Der Verein Ladiez. Kulturelle und politische Bildung für Frauen e.V. besteht seit 2013. Er ist aus einer Gruppe von Frauen hervorgegangen, die bereits zuvor in Frankfurt/M. feministische Veranstaltungen, Seminare sowie andere Bildungsangebote organisiert und durchgeführt haben. Gründungsidee war die zahlreichen Versuche feministischer Organisierung in Frankfurt/M. und darüber hinaus im gesamten Rhein-Main-Gebiet zu bündeln und Möglichkeiten des Austauschs für die verschiedenen Akteur:innen zu schaffen. Für Ladiez e.V. ist zum einen Netzwerkarbeit von großer Bedeutung. Zum anderen ist es dem Verein wichtig, an die Arbeit der Frauenbildung der  letzten Jahrzehnte anzuknüpfen und durch verschiedene Veranstaltungsformate feministische Diskussionen weiterzuführen. Ladiez e.V. bietet auch Fortbildungen für Mitarbeiter:innen der feministischen Mädchenarbeit an.",
        second: "Hier geht’s zur Website von ",
        secondLink: "Ladiez e.V."
    },
    english: {
        arrow: "<< ",
        headline: "COOPERATIONS >>",
        secondHeadline: "Ladiez e.V.",
        first: "The association Ladiez. Kulturelle und politische Bildung für Frauen e.V. exists since 2013. It emerged from a group of women who had already, before founding this association, organized and carried out feminist events, seminars as well as other educational offers in Frankfurt/M. The founding idea of Ladiez e.V. was to bundle the numerous attempts of feminist organizing in Frankfurt/M. as well as in the entire Rhine-Main region and to create opportunities for exchange for the various actors in this area. For Ladiez e.V. feminist networking is of great importance. Furthermore, the association wants to continue the work of women's education of the past decades and to continue feminist discussions through various formats. Ladiez e.V. also offers further trainings for social workers in the field of feminist girls’ work.",
        second: "Click here for the website of ",
        secondLink: "Ladiez e.V."
    }
}
export {ladiezContent}