import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/kooperationspartner.css"
import { ladiezContent } from "../../content/kooperationen/ladiez"
import { SettingsContext } from "../../contexts/settings"

const LadiezPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = ladiezContent[settings.language]
    return (
        <Template currentPage="cooperations">
            <section>
                <div>
                    <h1>
                        <Link to="/kooperationen" class="back-arrow">{content.arrow}{content.headline}</Link>
                    </h1>
                    <h2>{content.secondHeadline}</h2>
                    <div className="pic-text">
                        <div className="pic">
                            <img width="100%" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Ladiez.png" : "/logos/Kooperationspartner/bw/bw_Logo_Ladiez.png"} alt="Logo der feministischen Philosophinnen"/>
                        </div>
                        <div className="text">
                            <p>
                                {content.first}
                            </p>
                            <p className="end-of-site">
                                {content.second}
                                <a className="link" href="https://ladiezffm.wordpress.com/" target="_blank" rel="noopener noreferrer">{content.secondLink}</a>
                            </p>
                        </div> 
                    </div> 
                </div>    
            </section>
        </Template >
    )
}

export default LadiezPage